import React from "react"
import { useRecoilState } from "recoil"
import { collapsedState } from "../../utils/recoil-atoms"
import { Link } from "gatsby"
import logo from "../../assets/images/logo.png"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Navbar = () => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState)

  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  React.useEffect(() => {
    let elementId = document.getElementById("navbar")
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky")
      } else {
        elementId.classList.remove("is-sticky")
      }
    })
    window.scrollTo(0, 0)
  })

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show"
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right"

  return (
    <React.Fragment>
      <div id="navbar" className="navbar-area">
        <div className="tarn-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link
                to="/"
                onClick={() => setCollapsed(true)}
                className="navbar-brand"
              >
                <img src={logo} alt="logo" />
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/" activeClassName="active" className="nav-link">
                      Inicio
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/quienes-somos"
                      activeClassName="active"
                      className="nav-link"
                    >
                      Quienes somos
                    </Link>
                  </li>
                  <li className="nav-item">
                    <AnchorLink
                      to="/#servicios"
                      title="Servicios"
                      className={"nav-link"}
                    >
                      <span>Servicios</span>
                    </AnchorLink>
                  </li>
                  <li className="nav-item">
                    <AnchorLink
                      to="/blog"
                      title="Blog"
                      className={"nav-link"}
                    >
                      <span>Blog</span>
                    </AnchorLink>
                  </li>
                  {/*<li className="nav-item">
                                        <Link
                                            to="/servicios"
                                            activeClassName="active"
                                            onClick={e => e.preventDefault()}
                                            className="nav-link"
                                        >
                                            Nuestros servicios <i className='bx bx-chevron-down'></i>
                                        </Link>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <Link
                                                    to="/servicios/factura-electronica"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Factura y boleta Electronica
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/servicios/escritorio-digital"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Escritorio Digital
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/servicios/cobranza-automatizada"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Cobranza Automatizada
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/servicios/flujos-de-aprobacion"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Flujos de Aprobación
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/servicios/gestion-de-compras"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Gestión de Compras
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/servicios/gestion-de-contratos"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Gestión de Contratos
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/servicios/portal-proveedores"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Portal Proveedores
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to="/servicios/rendicion-de-gastos"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Rendición de Gastos
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>*/}

                  {/*<li className="nav-item">
                                        <Link
                                            to="/casos-de-exito"
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            Casos de Éxito
                                        </Link>
                                    </li>*/}
                </ul>

                <div className="others-option d-flex align-items-center">
                  <div className="option-item">
                    <Link
                      to="https://portal.febos.cl"
                      activeClassName="active"
                      onClick={() => setCollapsed(true)}
                      className="default-btn"
                    >
                      <i className="flaticon-right"></i> Ingresar <span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Navbar
